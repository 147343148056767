@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');

body {
  margin: 0 0 0 0;
  font-family: 'Special Elite', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color:white;
}

p {
  text-align: left;
  font-size:18px;
}

.redtext {
  color:#cf1616;
}

a:link,
a:visited {
  color:white;
  text-decoration: underline;
}

a:hover {
  text-decoration: underline;
  color:#cf1616;
}