@import url(https://fonts.googleapis.com/css2?family=Special+Elite&display=swap);
body {
  margin: 0 0 0 0;
  font-family: 'Special Elite', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color:white;
}

p {
  text-align: left;
  font-size:18px;
}

.redtext {
  color:#cf1616;
}

a:link,
a:visited {
  color:white;
  text-decoration: underline;
}

a:hover {
  text-decoration: underline;
  color:#cf1616;
}
.App {
  text-align: center;
}

.App-header {
  position:relative;
  top:0;
  /* border-bottom:1px solid white; */
}

.App-header img {
  /* position:relative; */
  /* border-bottom:1px solid white; */
}

.App-link {
  color: #61dafb;
}

.header-img {
  position:relative;
  top:0;
  width:100%;
  max-width:1920px;
}

.logo-img {
  position:absolute;
  top:30%;
  left:5%;
  max-width:50%;
}

.copy-cont {
  position:absolute;
  width:50%;
  left:6%;
  /* border-top:1px solid #ac1212; */
  top:40%;
  margin-top:20px;
}

@media only screen and (max-width: 600px) {
  .copy-cont {
    width:85%;
    margin-top:0;
    top:100%;
    margin-top:20px;
  }

  .logo-img {
    top:95%;
    max-width:75%;
  }

  p {
    font-size:14px;
  }
}

@media only screen and (min-width: 600px) {
  .copy-cont {
    width:60%;
  }

  .logo-img {
    top:30%;
    max-width:60%;
  }

  p {
    font-size:18px;
  }
}

@media only screen and (min-width: 768px) {

}

@media only screen and (min-width: 992px) {
  .copy-cont {
    width:50%;
  }

  .logo-img {
    top:30%;
    max-width:55%;
  }

  p {
    font-size:18px;
  }
}

@media only screen and (min-width: 1200px) {

}
